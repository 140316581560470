import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { heading, metaDescription, title } from 'constants/meta';
import MainTemplate from 'components/templates/MainTemplate';
import Link from 'components/atoms/Link';
import Button from 'components/atoms/Button';
import SendMessageToOffice from 'components/organisms/SendMessageToOffice';
import FeedbackCard from './FeedbackCard';

const Title = styled.h1`
  font-weight: normal;
`;

const MessageButton = styled(Button)``;

const FeedbacksPage = () => {
  const [isMessageModalShown, setMessageModalState] = useState(false);

  function handleMessageModalShow() {
    setMessageModalState(true);
  }

  function handleMessageModalHide() {
    setMessageModalState(false);
  }

  return (
    <MainTemplate>
      <Helmet>
        <title>{title.feedbacks}</title>
        <meta name="keywords" content="" />
        <meta name="description" content={metaDescription.feedbacks} />
      </Helmet>
      <Title>{heading.feedbacks}</Title>
      <SendMessageToOffice
        title="Отзыв"
        subject="Новый отзыв с сайта"
        successMessage="Ваш запрос успешно отправлен."
        isModalShown={isMessageModalShown}
        onModalHide={handleMessageModalHide}
        messageRequired
      />
      <FeedbackCard date="25 сентября 2023 года" title="Марина, Партизанск, Приморский край">
        <p>
        Вы очень круто работаете, многим фирмам бы поучиться у Вашей! Сотрудничаю с Вами больше 10 лет (с перерывом) всегда, ВСЕГДА всё четко! Спасибо большое!
        </p>
      </FeedbackCard>
      <FeedbackCard date="21 сентября 2023 года" title="Максим, Казань">
        <p>
        Большой ассортимент, профессиональный персонал помогают советом. Всегда стараются удовлетворить все запросы или принимают заказ если в данный момент нет вашего запроса.
        </p>
        <p>
        Отзыв из бизнес справочника Яндекса.
        </p>
      </FeedbackCard>
      <FeedbackCard date="18 мая 2020 года" title="Сергей, Крым">
        <p>
          Спасибо большое сотрудникам магазина Золотая Камея, большие молодцы, быстро отправили,
          качественный товар, покупал литейно-вакуумную установку, пару опок, вулканизатор с
          комплектующими, всё четко и надежно, СПАСИБО!!!
        </p>
        <p>
          Отзыв с сайта.{' '}
          <Link target="_blank" href="/catalog/Lite">
            Оборудование и материалы для ювелирного литья металлов
          </Link>
        </p>
      </FeedbackCard>
      <FeedbackCard date="30 апреля 2020 года" title="Александр, Беларусь">
        <p>
          Заказ №3717082587 делал в интернет-магазине. Единственное, что огорчило - это отсутствие
          крепежного винта в переходнике крепления рукава FOREDOM. Вероятно, что крепежный винт
          отсутствовал изначально в поставке. Кстати сам переходник из пластика лучшего качества,
          чем тот что изначально был с бормашиной. В целом, всеми полученными товарами и их
          качеством доволен! Магазин рекомендую!
        </p>
        <p>
          Отзыв с сайта.{' '}
          <Link target="_blank" href="/catalog/Bormashiny-i-aksessuary">
            Бормашины и аксессуары
          </Link>
        </p>
        <p>
          <b>Ответ магазина</b>: Александр, спасибо за отзыв! Крепежный винт можем выслать Вам
          отдельной посылкой или в следующем заказе.
        </p>
      </FeedbackCard>
      <FeedbackCard date="27 апреля 2020 года" title="Виктор, Сахалин">
        <p>
          Посылка дошла, даже сейчас в такое время. Особенно сложно доставить на остров Сахалин.
          Упаковано все прекрасно, особенно опоки и восковки, очень за них переживали. Качество
          товара очень удивило и обрадовало, особенно надфиля, у которых номер насечки №4 и
          флацанки. Дальше надеемся на сотрудничество, так как будем открывать другую мастерскую и
          здесь закажем много расходников и оборудования. Рекомендовать Золотую Камею обязательно
          будем, спасибо коллективу!
        </p>
        <p>
          Отзыв с сайта.{' '}
          <Link target="_blank" href="/catalog/opoki-i-voskovki">
            Одноразовые ювелирные опоки и восковки
          </Link>
        </p>
      </FeedbackCard>
      <FeedbackCard date="17 апреля 2020 года" title="Александр, Пинск, Беларусь">
        <p>
          Превосходный магазин и товары! Заказывал бормашину. Была проблема с заказом по вине
          транспортировщиков, но не смотря на это, мне заменили испорченый товар. Остался доволен
          сервисом! Продолжу заказывать в этом магазине, а так же друзьям и знакомым буду всячески
          советовать.
        </p>
        <p>
          Отзыв с сайта.{' '}
          <Link target="_blank" href="/catalog/Bormashiny">
            Бормашины
          </Link>
        </p>
      </FeedbackCard>
      <FeedbackCard date="24 февраля 2020 года" title="Роман, г. Рубцовск, Алтайский край">
        <p>Всё супер,покупкой доволен!</p>
        <p>
          Отзыв с сайта.{' '}
          <Link target="_blank" href="/catalog/Pajka">
            Оборудование и инструмент для ювелирной пайки
          </Link>
        </p>
      </FeedbackCard>
      <FeedbackCard date="17 февраля 2020 года" title="Владимир, г. Энгельс, Саратовская обл.">
        <p>
          Заказ получил на седьмой день. Качественная упаковка, весь товар дошел в хорошем
          состоянии. Всем большое спасибо!
        </p>
        <p>
          Отзыв с сайта.{' '}
          <Link target="_blank" href="/catalog/opoki-i-voskovki">
            Китайские опоки
          </Link>
        </p>
      </FeedbackCard>
      <FeedbackCard date="23 января 2020 года" title="Андрей, Курская область">
        <p>
          Выражаю благодарность коллективу магазина. Заказал товар с предоплатой, посылку отправили
          в тот же день почтой России, пришла без задержек. Сотрудники были на связи и оперативно
          отвечали на вопросы!
        </p>
        <p>
          Отзыв с сайта.{' '}
          <Link target="_blank" href="/catalog/Bormashiny">
            Бормашины
          </Link>
        </p>
      </FeedbackCard>
      <FeedbackCard date="3 декабря 2019 года" title="Александр, Пятигорск">
        <p>Благодарю, всё пришло, всем доволен. Буду заказывать ещё!</p>
        <p>
          Отзыв с сайта.{' '}
          <Link target="_blank" href="/catalog/Pajka">
            Оборудование и инструмент для ювелирной пайки
          </Link>
        </p>
      </FeedbackCard>
      <FeedbackCard date="11 октября 2019 года" title="Андрей, Республика Саха (Якутия)">
        <p>Всё прекрасно. респект вашему магазину!</p>
        <p>
          Отзыв с сайта.{' '}
          <Link target="_blank" href="/catalog/Shlifovka-i-polirovka">
            Оборудование и расходные материалы для шлифовки и полировки
          </Link>
        </p>
      </FeedbackCard>
      <FeedbackCard date="18 июля 2019 года" title="Андрей, ЯНАО">
        <p>
          Спасибо большое! Всё пришло! Заказывал флюрин в ЯНАО. Быстрая доставка, качественная
          упаковка, всё класс! Спасибо!
        </p>
        <p>
          Отзыв с сайта.{' '}
          <Link target="_blank" href="/catalog/Sredstva-uhoda-za-yuvelirnymi-izdeliyami">
            Средства ухода за ювелирными изделиями
          </Link>
        </p>
      </FeedbackCard>
      <FeedbackCard date="3 июня 2019 года" title="Александр, г. Пятигорск">
        <p>
          Благодарю, все очень удобно и оперативно. Цены ниже чем на остальных сайтах, товаром очень
          доволен!
        </p>
        <p>Отзыв с сайта. Заказ оформлен в рамках акции "Доставка по России за 1 рубль" — </p>
        <Link target="_blank" href="/sales/cju81ruzhtdgo0b564nme0ypd">
          https://gold-kamea.ru/sales/&hellip;
        </Link>
      </FeedbackCard>
      <FeedbackCard date="19 апреля 2019 года" title="Юрий, г. Таганрог">
        <p>
          Сделал заказ в интернет магазине, очень доволен. Заказ пришёл в течении 5 дней. Оплатил
          после получения товара. За доставку ничего не взяли. Буду ещё пользоваться услугами
          Золотой Камеи!
        </p>
        <p>Отзыв с сайта. Заказ оформлен в рамках акции "Доставка по России за 1 рубль" — </p>
        <Link target="_blank" href="/sales/cju81ruzhtdgo0b564nme0ypd">
          https://gold-kamea.ru/sales/&hellip;
        </Link>
      </FeedbackCard>
      <FeedbackCard date="30 марта 2019 года" title="Димитрий Ступин, Алтайский край">
        <p>
          Сделал первый заказ в данном интернет мазазине, и был приятно удивлён, а именно, качеством
          и быстротой реагирования. Всё доходчиво объяснили и ответили на мои вопросы, и самый
          приятный бонус - посылку отправили в этот же день после разговора с менеджером. Получил я
          её на 7 день. И при том, что это почта России, а живу я на Алтае в самом отдалённом его
          уголке. Я очень доволен работой интернет магазина и всем советую - качество и обслуживание
          на высоте.
        </p>
        <p>
          Отзыв из Google Maps —{' '}
          <Link
            target="_blank"
            href="https://www.google.com/maps/contrib/106039035676668929099/place/ChIJB_au0R6tXkERnPp_uCOM9XQ/@55.8289334,49.0801993,17z/data=!3m1!4b1"
          >
            https://www.google.com/maps&hellip;
          </Link>
        </p>
      </FeedbackCard>
      <FeedbackCard
        date="2 января 2019 года"
        title="Алексей, г. Норильск, победитель предновогоднего розыгрыша призов!"
      >
        <p>
          Впервые стал участником розыгрыша призов, и оказался первым. Хороший подарок к новому
          году. Выражаю благодарность директору и работникам магазина! Хороший ассортимент, приятные
          цены, быстрый отклик на заказ и отправка на адрес транспортной компанией, так как нахожусь
          в другом регионе. Спасибо вам! С Новым 2019 годом!
        </p>
        <p>
          Отзыв оставлен в нашей группе ВК под видео с результатами розыгрыша призов —{' '}
          <Link
            target="_blank"
            href="https://vk.com/goldkamea?z=video-75260575_456239020%2F99ef90552b5bebdc48%2Fpl_wall_-75260575"
          >
            https://vk.com/goldkamea?z=video-75260575_456239020&hellip;
          </Link>
        </p>
      </FeedbackCard>
      <FeedbackCard date="11 мая 2017 года" title="Александр, г. Южно- Сахалинск">
        <p>
          Сделал заказ в интернет-магазине «Золотая Камея». Все рассказали, все объяснили, посылка
          была отправлена в тот же день. Получил посылку через пять дней! Это на Дальний Восток!
          Такой быстрой доставки у меня еще не было. Доставка через транспортную компанию CDEK. Все
          пришло в сохранности и качество товара соответствует описанию на сайте. Спасибо
          сотрудникам магазина за отличную работу! Делайте покупки в магазине «Золотая Камея» и вы
          останетесь довольны.
        </p>
      </FeedbackCard>
      <MessageButton primary onClick={handleMessageModalShow}>
        Написать отзыв
      </MessageButton>
    </MainTemplate>
  );
};

export default FeedbacksPage;
