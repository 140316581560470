import styled from 'styled-components';

const Wrapper = styled.div`
  border: 0.0625em solid ${({ theme }) => theme.colors.neutral4};
  border-radius: 0.25em;
  padding: 0.5em;
  margin-bottom: 1em;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    padding: 1em;
  }

  p:last-child {
    margin-bottom: 0;
  }
`;

const Title = styled.h2`
  font-size: 1.2em;
  font-weight: 600;
  margin: 0 0 0.5rem 0;
`;

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.neutral3};
  margin: 0 0 0.5rem 0;
  display: block;
`;

const FeedbackCard = ({ date, title, children }) => (
  <Wrapper>
    <Subtitle>{date}</Subtitle>
    <Title>{title}</Title>
    {children}
  </Wrapper>
);

export default FeedbackCard;
